var getUrl = window.location;

export var apiLink = getUrl .protocol + "//" + getUrl.host + "/" + "api/";
export var loginLink = getUrl .protocol + "//" + getUrl.host + "/"

if (process.env.REACT_APP_DEBUG === "true") {
    //apiLink = "http://localhost:81/"
    apiLink = "http://localhost:81/api/"
    loginLink = "http://localhost:81/"
   // apiLink = "http://api.stagingkube.imok.fr/api/"
   // loginLink = "http://api.stagingkube.imok.fr/"

  }

  //apiLink = "http://localhost:3000/api/"
   // loginLink = "http://localhost:3000/"
