import React from "react";
import { Button, Container, Row, Col, Form, Image } from "react-bootstrap";
import logoimok from "../../image/logowhite.png";
import { apiLink, loginLink } from "../../Global";
import { Redirect } from "react-router-dom";

class Signin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      redirect: false,
    };
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.handleChangeUsername = this.handleChangeUsername.bind(this);
  }

  //Password
  handleChangePassword(event) {
    this.setState({ password: event.target.value });
  }

  //Cahnge Username
  handleChangeUsername(event) {
    this.setState({ username: event.target.value });
  }

  login = (e) => {
    e.preventDefault();
    var form = new FormData();
    form.set("username", this.state.username);
    form.set("password", this.state.password);
//alert("hello");
//alert(loginLink);
    try {
      fetch(loginLink + "login/", {
        method: "post",
        body: form,
        credentials: "same-origin",
      })
      .then(responseJSON => responseJSON.json())
      .then((response) => {
        if (response.code === 200) {
      //   alert("help");
    //     console.log(response.data.token);
          this.loginJWT(response.data.token)
        } else {
          alert("Invalid credentials");
        }
      });
  

    } catch (e) {

      console.log(e);
      alert(e);
    }
  };

  loginJWT = (token) => {
    try {
      //alert(loginLink);
      fetch(loginLink + "aldebaran/", {
        method: "post",
        headers: { 'Authorization': `Bearer ${token}`},
      })
      .then(responseJSON => responseJSON.json())
      .then((response) => {
        if (response.code === 200) {
          this.setState({redirect: true})
        } else {
          alert("Invalid credentials");
        }
      });
    } catch (e) {
      console.log(e);
   
    }
  }

  render() {
    const { redirect } = this.state;

    if (redirect) {
      return <Redirect to="/home" />;
    }
    return (
      <Container className=" h-100">
        <Row className=" align-items-center justify-content-center  h-100">
          <Col md={8} xs={11} className="newshadow corner_radius">
            <Row className=" align-items-center justify-content-center">
              <div className=" bg_dark header_login ">
                <Image src={logoimok} fluid />
              </div>
            </Row>

            <Row className="align-self-center">
              <div className=" d-flex justify-content-center body_login  bg_white ">
                <Col md={8} className=" md-push-2">
                  <div className="aldebran_title">
                    <h2>Aldebaran</h2>
                    <h5>screen editor</h5>
                  </div>
                  <Form>
                    <Form.Group>
                      <Form.Control
                        type="text"
                        placeholder="Username"
                        value={this.state.username}
                        onChange={this.handleChangeUsername}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Control
                        type="password"
                        placeholder="password"
                        value={this.state.password}
                        onChange={this.handleChangePassword}
                      />
                    </Form.Group>
                    {/* <p className="text-danger">Error</p> */}
                    <Button onClick={this.login} className="my-5 w-100">
                      LOGIN
                    </Button>
                  </Form>
                </Col>
              </div>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Signin;
